import React, { useEffect, useState } from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout";
import SEO from "../components/seo";
import { Breadcrumb, BreadcrumbItem } from "../components/Breadcrumb";
import PageContainer from "../components/PageContainer";
import {
  ContentsWrapper,
  ProcedureWrapper,
  Procedure,
  Contents,
  LeftContents,
  RightContents,
  ImageTextContents,
  ImageWrapper,
  DotWrapper,
  Dot,
  ProcedureContents,
  ProcedureTitle,
  TextWrapper,
  ProcedureSubContents,
  Title,
  ServiceDescriptionContainer,
  ServiceDescriptionContents,
  TreatWrapper,
  OtherImageWrapper,
  StyledImg,
  TextContents,
  SplintImageWrapper
} from "./serviceDetailStyles";

import ReactCompareImage from "react-compare-image";
export default ({ data }) => {
  const service = data.servicesJson;
  const img = data.mainImage?.childImageSharp.fluid;
  const social = data.social.childImageSharp.resize.src;
  const socialWidth = data.social.childImageSharp.resize.width;
  const socialHeight = data.social.childImageSharp.resize.height;
  const dentalSplintImg = data.dentalSplint?.childImageSharp.fluid;
  const {
    id,
    title,
    description,
    beforeFilePath,
    afterFilePath,
    beforeTreatFilePath,
    afterTreatFilePath,
    before2FilePath,
    after2FilePath,
    before2TreatFilePath,
    after2TreatFilePath,
    before3FilePath,
    after3FilePath,
    step1Contents,
    step1SubContents,
    step2Contents,
    step2SubContents,
    step3Contents,
    step3SubContents,
    serviceDescription1,
    serviceDescription2,
    dentalSplint,
  } = service;

  const link = "/services/" + id;

  var before = require("../images/" +
    (beforeFilePath || "dental-services/before-after/implant-before.jpg"));
  var after = require("../images/" +
    (afterFilePath || "dental-services/before-after/implant-after.jpg"));
  var beforeTreat = require("../images/" +
    (beforeTreatFilePath || "dental-services/before-after/implant-before.jpg"));
  var afterTreat = require("../images/" +
    (afterTreatFilePath || "dental-services/before-after/implant-after.jpg"));

  var before2 = require("../images/" +
    (before2FilePath || "dental-services/before-after/implant-before.jpg"));
  var after2 = require("../images/" +
    (after2FilePath || "dental-services/before-after/implant-after.jpg"));
  var before2Treat = require("../images/" +
    (before2TreatFilePath || "dental-services/before-after/implant-before.jpg"));
  var after2Treat = require("../images/" +
    (after2TreatFilePath || "dental-services/before-after/implant-after.jpg"));
  var before3 = require("../images/" +
    (before3FilePath || "dental-services/before-after/implant-before.jpg"));
  var after3 = require("../images/" +
    (after3FilePath || "dental-services/before-after/implant-after.jpg"));

  return (
    <Layout>
      <SEO
        title={"dental services: " + title}
        image={{
          metaImage: {
            src: social,
            width: socialWidth,
            height: socialHeight,
          },
        }}
      />

      <Breadcrumb pageTitle={title}>
        <BreadcrumbItem Link="/services" Text="Our Services" />
        <BreadcrumbItem Link={link} Text={title} />
      </Breadcrumb>
      <PageContainer>
        <Title>{title}</Title>
        <ContentsWrapper>
          <LeftContents>
            <TextContents>{description}</TextContents>
            {id === 'tmd' && <SplintImageWrapper><StyledImg fluid={dentalSplintImg} /></SplintImageWrapper>}
          </LeftContents>
            
          <ImageWrapper>
            <ReactCompareImage leftImage={before} rightImage={after} />
          </ImageWrapper>
        </ContentsWrapper>
        <ProcedureWrapper>
            <ServiceDescriptionContainer>
              <DotWrapper>
                <Dot />
              </DotWrapper>
              <TextWrapper>
                <ProcedureTitle>
                  We are here to help when you need us
                </ProcedureTitle>
              </TextWrapper>
              <TreatWrapper>
                <ImageWrapper>
                  <ReactCompareImage leftImage={beforeTreat} rightImage={afterTreat} />
                </ImageWrapper>
                <RightContents>
                  {serviceDescription1}{'\n\n\n'}{serviceDescription2}
                </RightContents>
              </TreatWrapper>
            </ServiceDescriptionContainer>
          </ProcedureWrapper>
        {!step1Contents || (
          <>
            <ProcedureWrapper>
              <Procedure>
                <DotWrapper>
                  <Dot />
                </DotWrapper>
                <TextWrapper>
                  <ProcedureTitle>Step1</ProcedureTitle>
                  <ProcedureContents>{step1Contents}</ProcedureContents>

                  <ProcedureSubContents>
                    {step1SubContents}
                  </ProcedureSubContents>
                </TextWrapper>
              </Procedure>
              <Procedure>
                <DotWrapper>
                  <Dot />
                </DotWrapper>
                <TextWrapper>
                  <ProcedureTitle>Step2</ProcedureTitle>
                  <ProcedureContents>{step2Contents}</ProcedureContents>
                  <ProcedureSubContents>
                    {step2SubContents}
                  </ProcedureSubContents>
                </TextWrapper>
              </Procedure>
              <Procedure>
                <DotWrapper>
                  <Dot />
                </DotWrapper>
                <TextWrapper>
                  <ProcedureTitle>Step3</ProcedureTitle>
                  <ProcedureContents>{step3Contents}</ProcedureContents>
                  <ProcedureSubContents>
                    {step3SubContents}
                  </ProcedureSubContents>
                </TextWrapper>
              </Procedure>
            </ProcedureWrapper>
          </>
        )}
        {(id === 'crowns' || id === 'veneers') && (
          <ProcedureWrapper>
            <ServiceDescriptionContainer>
              <DotWrapper>
                <Dot />
              </DotWrapper>
              <TextWrapper>
                <ProcedureTitle>
                  Other {title} Cases
                </ProcedureTitle>
              </TextWrapper>
              <TreatWrapper>
                <OtherImageWrapper>
                  <ReactCompareImage leftImage={before2Treat} rightImage={after2Treat} />
                </OtherImageWrapper>
                <OtherImageWrapper>
                    <ReactCompareImage leftImage={before2} rightImage={after2} />
                </OtherImageWrapper>
                <OtherImageWrapper>
                  <ReactCompareImage leftImage={before3} rightImage={after3} />
                </OtherImageWrapper>
              </TreatWrapper>
            </ServiceDescriptionContainer>
          </ProcedureWrapper>
        )}
      </PageContainer>
    </Layout>
  );
};

export const pageQuery = graphql`
  fragment ServiceImage on File {
    childImageSharp {
      fluid(maxWidth: 2400) {
        ...GatsbyImageSharpFluid
      }
    }
  }
  fragment SocialImage on File {
    childImageSharp {
      resize(width: 1200) {
        src
        width
        height
      }
    }
  }
  fragment ImageDentalSplint on File {
    childImageSharp {
      fluid(maxWidth: 800) {
        ...GatsbyImageSharpFluid
      }
    }
  }
  query($id: String!, $imageFilePath: String!) {
    servicesJson(id: { eq: $id }) {
      id
      title
      description
      beforeFilePath
      afterFilePath
      beforeTreatFilePath
      afterTreatFilePath
      before2FilePath
      after2FilePath
      before2TreatFilePath
      after2TreatFilePath
      before3FilePath
      after3FilePath
      step1Contents
      step1SubContents
      step2Contents
      step2SubContents
      step3Contents
      step3SubContents
      serviceDescription1
      serviceDescription2
    }
    mainImage: file(relativePath: { eq: $imageFilePath }) {
      ...ServiceImage
    }

    social: file(relativePath: { eq: $imageFilePath }) {
      ...SocialImage
    }
    dentalSplint: file(relativePath: { eq: "dental-services/before-after/TMD splint.jpg" }) {
      ...ImageDentalSplint
    }
  }
`;
