import styled from "styled-components";
import { breakpoints, colors } from "../styles/variables";
import Img from "gatsby-image";

export const ContentsWrapper = styled.div`
  padding-top: 0;
  padding-bottom: 5rem;
  display: flex;
  justify-contents: flex-start;
  align-items: flex-start;
  flex-direction: column;
  @media (min-width: ${breakpoints.md}) {
    padding-top: 2rem;
    padding-bottom: 6rem;
    flex-direction: row;
  }
`;
export const TreatWrapper = styled(ContentsWrapper)`
  flex-direction: column-reverse;
  @media (min-width: ${breakpoints.md}) {
    flex-direction: row;
    justify-content: center;
  }
`
export const Title = styled.h1``;
export const Contents = styled.div`
  width: 100%;
  margin: 0 auto 5rem;
  font-size: 1rem;
  white-space: pre-line;
  @media (min-width: ${breakpoints.md}) {
    width: 50%;
  }
`;
export const LeftContents = styled(Contents)`
  @media (min-width: ${breakpoints.md}) {
    margin: 0 2rem 0 0;
  }
`;
export const RightContents = styled(Contents)`
  @media (min-width: ${breakpoints.md}) {
    margin: 0 0 5rem 2rem;
  }
`;
export const TextContents = styled.p`
  width: 100%;
  font-size: 1rem;
  white-space: pre-line;
`;
export const ImageTextContents = styled(Contents)`
  margin: 1rem 0;
  text-align: center;
  @media (min-width: ${breakpoints.md}) {
    width: 100%;
  }
`;
export const ImageWrapper = styled.div`
  width: 100%;
  @media (min-width: ${breakpoints.md}) {
    width: 50%;
  }
`;
export const SplintImageWrapper = styled.div`
  width: 70%;
  margin:0 auto;
`;
export const OtherImageWrapper = styled(ImageWrapper)`
  padding: 1.5rem 1rem;
  @media (min-width: ${breakpoints.md}) {
    padding: 0 1rem;
  }
`

export const ProcedureWrapper = styled.div`
  display: block;
  margin: 0 -15px;
  position: relative;
  padding-top: 28px;
  &::before {
    content: "";
    width: calc(100% - 30px);
    height: 1px;
    border-top: 1px dashed #d4d2d2;
    position: absolute;
    top: 14px;
    left: 15px;
  }
  @media (min-width: ${breakpoints.sm}) {
    display: flex;
  }
`;
export const Line = styled.div`
  content: "";
  width: calc(100% - 30px);
  height: 1px;
  border-top: 1px dashed #d4d2d2;
  position: absolute;
  top: 14px;
  left: 15px;
`;

export const Procedure = styled.div`
  width: 100%;
  position: relative;
  margin-bottom: 3rem;
  padding: 0 1rem;
  @media (min-width: ${breakpoints.sm}) {
    width: calc(100% / 3);
    margin-bottom: 0;
  }
`;
export const DotWrapper = styled.div`
  height: 28px;
  position: relative;
  margin-bottom: 20px;
  @media (min-width: ${breakpoints.sm}) {
    margin-bottom: 0;
  }
`;
export const Dot = styled.div`
  width: 28px;
  height: 28px;
  border-radius: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
  margin-top: -1.7rem;
  background-color: rgba(82, 203, 203, 0.18);
  &::before {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 18px;
    height: 18px;
    border-radius: 50%;
    background-color: rgba(82, 203, 203, 0.29);
  }
  &::after {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: ${colors.dentCyan};
  }
`;

export const ProcedureTitle = styled.h5`
  font-weight: 700;
  font-size: 20px;
  margin-bottom: 1.5rem;
`;
export const ProcedureContents = styled.div`
  font-size: 1rem;
  margin: 0 auto 1rem auto;
  position: relative;
  padding: 0 1rem;
`;
export const ProcedureSubContents = styled.p`
  padding-bottom: 2rem;
`;
export const TextWrapper = styled.div`
  text-align: center;
  position: relative;
  padding: 0 25px;
  text-align: center;
`;
export const ServiceDescriptionContainer = styled.div`
  width: 100%;
  position: relative;
  margin-bottom: 3rem;
  padding: 0 1rem;
`;
export const ServiceDescriptionContents = styled.p`
  padding-bottom: 0.5rem;
  max-width: 57.75rem;
  margin: 0 auto 0.5rem;
`;
export const StyledImg = styled(Img)`
  width: 100%;
  heigh: 100%;
`;
